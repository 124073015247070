.account-page img {
    max-width: 140px;
}
.toolbar {
    --background: #00467B;
    --color: white;
}

.div-botton-baja{
    text-align: center;
}

.botton-baja{
    --background: #0063A2;
}