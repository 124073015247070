.select-invoices {
    border: 2px solid #0063A2;

}

.label-invoices {
    text-align: center;
}
.toolbar {
    --background: #00467B;
    --color: white;
}

.header-list{
    --background: #008AC7;
    --color: white;
    text-align: left;
    margin:auto;
}

.botton-actions{
    --background: #008AC7;
}

ion-label{
    white-space:normal !important;
}