.title-card {
    text-align: center;
}

.list-header{
    text-align: left;
    margin:auto;
}

.botton-actions{
    --background: #0063A2;
}

.title-subheader{
    --background: #008AC7;
    --color: white;
}

.input-form{
    color: #0063A2 !important;
}

.radio-select{
    --color: #0063A2;
    --color-checked: #0063A2;
}