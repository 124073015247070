.login-logo {
    padding: 20px 0;
    min-height: 110px;
    margin: auto;
    width: 100px;
    height: 65px;

}

.login-logo img {
    max-width: 100px;
}

.toolbar {
    --background: #00467B;
    --color: white;
}