.list-header{
    text-align: left;
    margin:auto;
}

.one-col{
    margin-left: 20px;
    text-align: center;
}
.two-col{
    margin-left: 20px;
    text-align: center;
}

.three-col{
    text-align: right;
}

.toolbar {
    --background: #00467B;
    --color: white;
}

.header-list{
    --background: #008AC7;
    --color: white;
}