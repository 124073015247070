.label-text{
    color: #008AC7 !important;
}

.label-text-important{
    color: #00467B !important;
    font-weight: bold;
}

.divider-summary-selected-line{
    --background: #C5E0EA;
    min-height: 5px;
}

.divider-label-summary-selected-line{
    color: #00467B !important;
}
.rate-gb{
    font-size: 20px;
}