.title-card {
    text-align: center;
}

.text-info{
    text-align: left;
    margin:auto;
}

.btn_eye_icon{
    font-size:2rem !important;
    position: relative !important;
    top: 30px !important;
    margin: 0 auto !important;
}

.icon_eye{
    font-size: 24px; /* multiple of 8 (8, 16, 24, 32, etc.) */
    color: #0063A2;
}

.btn_eye_icon:focus{
    outline: none  !important;
}

.botton-actions{
    --background: #0063A2;
}

.input-form{
    color: #0063A2 !important;
}