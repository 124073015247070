.login-logo {
    padding: 20px 0;
    min-height: 110px;
    margin: auto;
    width: 100px;
    height: 65px;

}

.login-logo img {
    max-width: 100px;
}

.list {
    margin-bottom: 0;
}

.btn_eye_icon{
    font-size:2rem !important;
    position: relative !important;
    top: 30px !important;
    margin: 0 auto !important;
}

.icon_eye{
    font-size: 24px; /* multiple of 8 (8, 16, 24, 32, etc.) */
    color: #0063A2;
}

.btn_eye_icon:focus{
    outline: none  !important;
}

.botton-subactions{
    --background: #008AC7;
}

.botton-actions{
    --background: #0063A2;
}

.input-form{
    color: #0063A2 !important;
}

.checkbox-remember-passsword{
    max-width: 400px;
    color: #0063A2;
    border-bottom: none;
    font-size: 15px;
}

ion-checkbox {
    --border-color: #0063A2;
    --border-color-checked: #0063A2;
}

ion-item {
    --border-color: #0063A2;
}